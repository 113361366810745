export const colorsTheme = {
  white: '#FFFFFF',
  black: '#000000',
  danger: '#F2383A',

  'gradient-deg': '294deg',
  'gradient-primary': '#5B769E',
  'gradient-secondary': '#031633',

  'primary-color': '#0D6EFD',
  'secondary-color': '#052B63',
  'auxiliary-color': '#0D6EFD',

  'bg-color': '#FFFFFF',
  'bg-secondary-color': '#031633',
  'bg-auxiliary-color': '#EDEDED',

  'bg-circle-color': '#FFFFFF',
  'bg-circle-hover': '#C7DEFF',
  'bg-circle-text-color': '#000',
  'bg-circle-text-color-hover': '#FFFFFF',

  'filter-button-background': '#0D6EFD',
  'filter-button-color': '#FFFFFF',
  'filter-button-border': '#0D6EFD',
  'entry-message-color': '#0D6EFD',

  'bg-modal-color': '#FFFFFF',

  'dark-color': '#3F3F3F',

  'text-primary': '#1E1E1E',
  'text-secondary': '#0D6EFD;',
  'text-gray': '#4F4F4F',

  'input-color': '#1E1E1E',
  'bg-input-color': '#FFFFFF',
  'info-color': '#C7DEFF',
  'disabled-color': '#e2e2e2',
  'card-color': '#EEEEEE',
  'modal-border-color': '#ADB5BD',
  'border-color': '#0D6EFD',

  'echeck-label-color': '#000',
  'echeck-border-color': '#D2D2D2',
  'echeck-text-color': '#000',
  'echeck-bar-color1': '#0D6EFD',
  'echeck-bar-color2': '#0D6EFD'
};
